import clamp from "lodash/fp/clamp";
import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import orderBy from "lodash/fp/orderBy";
import reduce from "lodash/fp/reduce";
import { distanceBetweenPoints, filterItemsWithDiscScores } from "../../../utils";
import { discColorMapping, discIconMapping } from "../../TeamDashboard/utils";
import {
  getCenterOffset,
  groupAndFormatUsers,
  addCoordinatesFromScores
} from "../utils";

const DISC_CHART_PADDING = 15;
const DISC_RADIUS = 95;
const OVERLAP_THRESHOLD = 7.3;

/**
 * This is the *standard* layout for how the disc chart is drawn. You can create custom layouts and pass them to `formatDiscCoordinatesAndUsers`
 * This is also used in the visual drawing of the DISC wheel in `DiscWheelBackground`, in addition to the graphing logic.
 *
 * @property {String} letter - The DISC letter `D`, `I`, `S` or `C`.
 * @property {String} title - The fullname associated with this letter.
 * @property {String} color - The color associated with this DISC letter.
 * @property {String|Array<String>} icon - The icon associated with this letter.
 * @property {Number} order - The location in which this letter is placed on the chart. Order goes - top left (0), top right (1), bottom right (2), bottom left (3).
 */
export const DISC_CHART_LAYOUT = orderBy("order", "asc", [
  {
    letter: "D",
    title: "dominance",
    color: discColorMapping.D,
    icon: discIconMapping.D,
    order: 0,
  },
  {
    letter: "I",
    title: "influence",
    color: discColorMapping.I,
    icon: discIconMapping.I,
    order: 1,
  },
  {
    letter: "S",
    title: "steadiness",
    color: discColorMapping.S,
    icon: discIconMapping.S,
    order: 2,
  },
  {
    letter: "C",
    title: "conscientiousness",
    color: discColorMapping.C,
    icon: discIconMapping.C,
    order: 3,
  },
]);

export const DISC_CHART_LAYOUT_TTI = orderBy("order", "asc", [
  {
    letter: "D",
    title: "dominance",
    color: discColorMapping.D,
    icon: discIconMapping.D,
    order: 1,
  },
  {
    letter: "I",
    title: "influence",
    color: discColorMapping.I,
    icon: discIconMapping.I,
    order: 2,
  },
  {
    letter: "S",
    title: "steadiness",
    color: discColorMapping.S,
    icon: discIconMapping.S,
    order: 3,
  },
  {
    letter: "C",
    title: "conscientiousness",
    color: discColorMapping.C,
    icon: discIconMapping.C,
    order: 0,
  },
]);

const getDiscChartWeights = discLayout => ({
  [discLayout[0].letter]: {
    x: -0.75,
    y: 0.75,
  },
  [discLayout[1].letter]: {
    x: 0.75,
    y: 0.75,
  },
  [discLayout[2].letter]: {
    x: 0.75,
    y: -0.75,
  },
  [discLayout[3].letter]: {
    x: -0.75,
    y: -0.75,
  },
});

const getDiscQuadrantBounds = discLayout => ({
  [discLayout[0].letter]: {
    xMin: -100,
    xMax: -1 * DISC_CHART_PADDING,
    yMin: 1 * DISC_CHART_PADDING,
    yMax: 100,
  },
  [discLayout[1].letter]: {
    xMin: 1 * DISC_CHART_PADDING,
    xMax: 100,
    yMin: 1 * DISC_CHART_PADDING,
    yMax: 100,
  },
  [discLayout[2].letter]: {
    xMin: 1 * DISC_CHART_PADDING,
    xMax: 100,
    yMin: -100,
    yMax: -1 * DISC_CHART_PADDING,
  },
  [discLayout[3].letter]: {
    xMin: -100,
    xMax: -1 * DISC_CHART_PADDING,
    yMin: -100,
    yMax: -1 * DISC_CHART_PADDING,
  },
});

const getDiscScores = get("scores.disc.traits");

const reduceWithKey = reduce.convert({ cap: false });

const clampDiscCoordinatesToCircleBounds = (position) => {
  const center = { x: 0, y: 0 };
  const distanceFromCenter = distanceBetweenPoints(center.x, center.y);

  const distance = distanceFromCenter(position.x, position.y);
  const normalization = (DISC_RADIUS / distance);

  // If the distance between the center and the value is outside the circle, remap it to the circles edge
  if (distance > DISC_RADIUS) {
    const { xOffset, yOffset } = getCenterOffset(center, position);

    const x = Math.ceil(center.x + (xOffset * normalization));
    const y = Math.ceil(center.y + (yOffset * normalization));

    return { x, y };
  }

  return position;
};

// Using the highest letter score, we determine what quadrant they should remain in
export const clampDiscCoordinatesToQuadrantBounds = discLayout => (score) => {
  if (score && score.highestLetter) {
    const discQuadrantBounds = getDiscQuadrantBounds(discLayout);
    const quadrantBounds = discQuadrantBounds[score.highestLetter];
    const { xMin, xMax, yMin, yMax } = quadrantBounds;
    const x = clamp(xMin, xMax, score.x);
    const y = clamp(yMin, yMax, score.y);

    return { x, y };
  }

  return { x: 0, y: 0 };
};

export const discCoordinateReducer = discLayout => (accumulator, { value, name }, currentIndex) => {
  const discChartWeights = getDiscChartWeights(discLayout);

  if (currentIndex === 0) {
    accumulator.x += Math.ceil(value * discChartWeights[name].x);
    accumulator.y += Math.ceil(value * discChartWeights[name].y);
    accumulator.highestLetter = name; // Store this for later

    return accumulator;
  }

  // The following scores are weighted, because this is sorted from high to low,
  // It puts emphasis on the first two letter scores determining how it should be graphed
  const weight = value / (DISC_RADIUS);
  accumulator.x += Math.ceil(value * weight * discChartWeights[name].x);
  accumulator.y += Math.ceil(value * weight * discChartWeights[name].y);

  return accumulator;
};

const sortByScores = scores => orderBy("value", "desc", scores);

export const getDiscCoordinate = discLayout => scores => compose(
  clampDiscCoordinatesToCircleBounds,
  clampDiscCoordinatesToQuadrantBounds(discLayout),
  reduceWithKey(discCoordinateReducer(discLayout), { x: 0, y: 0 }),
  sortByScores,
)(scores);

const getDiscCoordinateFromScores = discLayout => scores => compose(
  getDiscCoordinate(discLayout),
  getDiscScores,
)(scores);

export const formatDiscCoordinatesAndUsers = (users, discLayout = DISC_CHART_LAYOUT) => compose(
  coordinates => groupAndFormatUsers(coordinates, OVERLAP_THRESHOLD),
  addCoordinatesFromScores(getDiscCoordinateFromScores(discLayout)),
  filterItemsWithDiscScores,
)(users);
