import React from "react";
import { arrayOf } from "prop-types";
import getOr from "lodash/fp/getOr";
import { Box } from "grommet";
import { publicUserPropType } from "../../../lib/graphql/propTypes";
import { TextLabel } from "../../ui";
import HorizontalUser from "../HorizontalUser";
import { discColorMapping } from "../TeamDashboard/utils";

const getDiscTraits = getOr([], "scores.disc.traits");

class DiscWheelTooltip extends React.PureComponent {
  static propTypes = {
    users: arrayOf(publicUserPropType),
  };

  static defaultProps = {
    users: [],
  };

  renderToolTip = user => (
    <Box key={user.id} pad="2px" background="white">
      <HorizontalUser user={user} />
      <Box pad={{ horizontal: "xsmall" }} justify="between" direction="row">
        {getDiscTraits(user).map(trait => (
          <Box key={trait.name} mr={1}>
            <TextLabel
              center
              key={trait.name}
              style={{ color: discColorMapping[trait.name] }}
            >
              <Box>{trait.name}</Box>
              <Box>{trait.value}</Box>
            </TextLabel>
          </Box>
        ))}
      </Box>
    </Box>
  );

  render() {
    const { users } = this.props;

    return users.map(this.renderToolTip);
  }
}

export default DiscWheelTooltip;
